import { Injectable, OnDestroy } from '@angular/core';
import { AbstractHttpService } from './abstract-http.service';
import { BehaviorSubject, ReplaySubject, Subject, Subscription, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { PanelClass, TranslatedSnackBarService } from '../translatedSnackBarService/translated-snack-bar.service';
import { catchError } from 'rxjs/operators';

export interface Lead {
  id: number;
  name: string;
  customer: any;
  topic: any;
  subTopic: any;
  manager: any;
  unit: any;
  runtimeFrom: any;
  runtimeTo: any;
  notes: any;
  psp: any;
}

@Injectable({
  providedIn: 'root',
})
export class LeadService extends AbstractHttpService implements OnDestroy {
  private readonly leadDataSubject$ = new BehaviorSubject<any>([]);
  public leadData$ = this.leadDataSubject$.asObservable();

  public readonly rowClickedSubject$ = new Subject<any>();
  public rowClicked$ = this.rowClickedSubject$.asObservable();

  public readonly createLeadClickedSubject$ = new Subject<any>();
  public createLeadClicked$ = this.createLeadClickedSubject$.asObservable();

  public readonly leadCreatedSubject$ = new Subject<any>();
  public leadCreated$ = this.leadCreatedSubject$.asObservable();

  public readonly dealsAndPlansAreEmptySubject$ = new BehaviorSubject<boolean>(true);

  /* Subscription for the delete Button in the header for deleting
   orders and roles in the sales area, it put it here because I didn't want
    to create a new service for one subscription */
  /* Using RelaySubject to ensure only the latest emitted event is processed,
   avoiding duplication or old events */
  public deleteButtonClickedSubject$ = new ReplaySubject<boolean>(1);

  /* Subscription for the selected tab in the sales area, it put it here
   because I didn't want to create a new service for one subscription */
  public selectedTabSubject$ = new BehaviorSubject<number>(0);
  public selectedTab$ = this.selectedTabSubject$.asObservable();
  private leadServiceSubscriptions = [];

  constructor(
    protected readonly http: HttpClient,
    protected readonly authService: AuthService,
    protected readonly translatedSnackBar: TranslatedSnackBarService
  ) {
    super(http, authService, translatedSnackBar, `/v1/lead`);
  }

  ngOnDestroy(): void {
    if (this.leadServiceSubscriptions) {
      this.leadServiceSubscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  public createOrUpdateLead(lead: Lead) {
    return this.http.post(this.path, lead).pipe(catchError(this.handleError.bind(this)));
  }

  public updateLeadData(): Subscription {
    const getSubscription = this.getAndSortById('').subscribe((data) => this.leadDataSubject$.next(data));
    this.leadServiceSubscriptions.push(getSubscription);
    return getSubscription;
  }

  public deleteAndUpdateLead(id: any): Subscription {
    const deleteSubscription = this.http
      .delete(this.path + '/' + id)
      .pipe(
        catchError(this.handleError.bind(this)),
        tap((response: any) =>
          this.translatedSnackBar.openDeletionResponseSnackbar(response, 'sales.delete-error.lead.')
        )
      )
      .subscribe((response) => {
        if (response.data) {
          this.updateLeadData();
        }
      });
    this.leadServiceSubscriptions.push(deleteSubscription);
    return deleteSubscription;
  }

  /**
   * calculates the validation range of each lead.
   * @param id  the id of the lead
   * */
  public calculateValidationRange(id: number) {
    const url = `${this.path}/validationRange/${id}`;
    const checkValidationDateSubscription = this.http
      .post(url, {})
      .pipe(catchError(this.handleError.bind(this)))
      .subscribe(() => {
        this.updateLeadData();
      });
    this.leadServiceSubscriptions.push(checkValidationDateSubscription);
    return checkValidationDateSubscription;
  }
}
