import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '../../../services/loadingService/loading.service';
import { AuthService } from '../../../services/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { SidenavService } from '../../../services/sidenavService/sidenav.service';
import { Subscription } from 'rxjs';
import { LeadService } from '../../../services/_http/lead.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isAuthenticated = false;
  isTab1Selected = false;
  private headerSubscriptions: Subscription[] = [];

  constructor(
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService,
    private readonly router: Router,
    public sidenavService: SidenavService,
    public translate: TranslateService,
    private readonly route: ActivatedRoute,
    protected readonly leadService: LeadService
  ) {
    this.headerSubscriptions.push(
      this.authService.isAuthenticated$.subscribe((next) => {
        this.isAuthenticated = next;
      }),
      this.leadService.selectedTab$.subscribe((tab) => {
        this.isTab1Selected = tab === 1;
      })
    );
  }

  ngOnInit(): void {
    /* Subscribe to navigation event to reset the table when the user quit
     the sales page */
    /* Todo: this events subscription is for now not unsubscribed and
           that could cause memory leaks, but if i unsubscribe it the method
            doesn't work any more, maybe it needs to be moved somewhere else */
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      if (event.url !== '/sales') {
        this.leadService.dealsAndPlansAreEmptySubject$.next(true);
        this.leadService.selectedTabSubject$.next(0);
        this.leadService.deleteButtonClickedSubject$.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.headerSubscriptions.forEach((s) => s.unsubscribe());
  }

  login() {
    this.loadingService.startLoading();
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  toggleSidenav() {
    this.sidenavService.toggle();
  }

  navigateToWelcome() {
    this.sidenavService.close();
    this.router.navigateByUrl('/');
  }

  isOnPage(pageName: string): boolean {
    return this.route.snapshot.url[0]?.path === pageName;
  }

  openDeleteDialog() {
    this.leadService.deleteButtonClickedSubject$.next(true);
  }
}
