import { NgModule } from '@angular/core';
import { DateFormatPipe } from './date-format';
import { PercentFormatPipe } from './percent-format';
import { SearchFilterPipe } from './search-filter.pipe';
import { MonthNamePipe } from './month-name';
import { TruncatePipe } from './truncate';
import { UnsortedKeyValuePipe } from './unsorted-keyvalue.pipe';

@NgModule({
  declarations: [DateFormatPipe, PercentFormatPipe, SearchFilterPipe, MonthNamePipe, TruncatePipe],
  exports: [DateFormatPipe, PercentFormatPipe, MonthNamePipe, TruncatePipe],
  imports: [UnsortedKeyValuePipe],
})
export class PipesModule {}
