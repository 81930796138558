import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export enum PanelClass {
  PRIMARY = 'snack-primary',
  ACCENT = 'snack-accent',
  WARN = 'snack-warn',
  YELLOW = 'snack-yellow',
}

@Injectable({
  providedIn: 'root',
})
export class TranslatedSnackBarService {
  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {}

  /** Displays a snackbar at the bottom of the screen with a message, that
   *  is translated automatically for a specific duration.
   * @param message The text message we want to be translated and displayed.
   * @param panelClass The color-scheme of type PanelClass
   * @param messageParameters Any information that is passed on to the
   * translation service.
   * @param displayDuration How long until the snackbar vanishes by itself in
   * milliseconds. Default = 5000, 0 = forever
   */
  openDefault(message: string, panelClass?: PanelClass, messageParameters?: any, displayDuration = 5000) {
    this.snackBar.open(this.translate.instant(message, messageParameters), this.translate.instant('dismiss'), {
      panelClass: [panelClass],
      duration: displayDuration,
    });
  }

  /** Displays a snackbar at the bottom of the screen with a message.
   * @param message The text message we want to be translated and displayed.
   * @param action What to display on the close button.
   * @param config An object with configuration for the snackbar as
   * specified in the Angular Material Docs
   * @param messageParameters Any information that is passed on to the
   * translation service.
   */
  open(message: string, action?: string, config?: MatSnackBarConfig, messageParameters?: any) {
    this.snackBar.open(
      this.translate.instant(message, messageParameters),
      action ? this.translate.instant(action) : this.translate.instant('dismiss'),
      config
    );
  }

  openDeletionResponseSnackbar(response: any, translationKey: string) {
    if (!response.data && response.businessMessages.length !== 0) {
      this.openDefault(translationKey + response.businessMessages[0].id, PanelClass.WARN);
    } else {
      this.openDefault('delete-successful', PanelClass.ACCENT);
    }
  }
}
